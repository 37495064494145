import { graphql } from 'gatsby'
import { GatsbyFluidImageProps } from 'gatsby-source-sanity'
import React from 'react'
import styled from 'styled-components'
import { layout } from '../components/GlobalStyles/vars/layout'
import { HeroImage } from '../components/HeroImage'
import { Layout } from '../components/Layout/Layout'
import { Listing } from '../components/Listing'
import { SEO } from '../helpers/SEO'
import { Block } from '../helpers/toPlainText'

const PageContainer = styled.div``

const ServicesContainer = styled.ul`
  ${layout.pageWidth}
  margin-top: 50px;
`

interface Data {
  data: {
    file: {
      childImageSharp: {
        fluid: GatsbyFluidImageProps
      }
    }
    allSanityPartner: {
      nodes: Array<Partner>
    }
  }
}

interface Partner {
  id: string
  mainImage: {
    asset: {
      fluid: {
        src: string
      }
    }
  }
  title: string
  seoData: {
    _rawDescription: React.ReactNode
  }
  slug: {
    current: string
  }
  certificates: Array<{ title: string }>
}

const Partners = ({ data }: Data): JSX.Element => {
  return (
    <>
      <SEO
        title="Partners"
        description="Meet the great team that makes this all posible. At Queen City we are a family. Check out the instructor partners and meet who you will work well with."
      />
      <Layout>
        <HeroImage fluid={data.file.childImageSharp.fluid} alt="An aircraft parked on the airport ramp being fueled." />
        <PageContainer>
          <h1>Meet the Team</h1>
          <p>
            Our instructor partners are our family at Queen City Flying Service. By working together with independent
            flight instructors we are able to provide excellent service to our clients, while helping our instructors
            grow.
          </p>
          <p>
            Every client is an individual with specific needs and desires, not a number to be pushed through as quickly
            as possible. The instructor’s first job is to determine which program will most benefit the new pilot. Then,
            our instructors work with each client to select a training package which best meets the client’s needs,
            experience level, and learning style.
          </p>
          <ServicesContainer>
            {data.allSanityPartner.nodes.map((partner) => {
              return (
                <Listing
                  title={partner.title}
                  subtitle={partner.certificates.map((cert) => cert.title).join(', ')}
                  img={partner.mainImage.asset.fluid.src}
                  key={partner.id}
                  desc={partner.seoData._rawDescription}
                  link={`/partners/${partner.slug.current}`}
                />
              )
            })}
          </ServicesContainer>
        </PageContainer>
      </Layout>
    </>
  )
}

export default Partners

export const query = graphql`
  {
    file(relativePath: { eq: "team.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allSanityPartner {
      nodes {
        id
        mainImage {
          asset {
            fluid(maxWidth: 100, maxHeight: 100) {
              src
            }
          }
        }
        title: name
        seoData {
          _rawDescription
        }
        slug {
          current
        }
        certificates {
          title
        }
      }
    }
  }
`
